import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Layout"
import ProductHeader from "gatsby-theme-rothenberger/src/components/blocks/ProductHeader"
import ProductCard from "gatsby-theme-rothenberger/src/components/molecules/ProductCard"
import Richtext from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Richtext"
import SEO from "gatsby-theme-rothenberger/src/@yumgmbh/gatsby-theme-yum-components/components/modules/Seo"
import { switchedObjectNameOrLabel } from "gatsby-theme-rothenberger/src/utilities/product-data-utils"

const ProductLineTemplate = ({ data }) => {
  const entry = data.entry
  const area = data.area
  const products = data.products.edges
  const productGroups = data.productGroups.edges

  let breadcrumbs = [
    { link: entry.rootPage.fullSlug, link_text: entry.rootPage.name },
    { link: area.fullSlug, link_text: area.name },
  ]

  return (
    <Layout language={entry.language}>
      <SEO
        title={`${entry.name} | ${process.env.GATSBY_PRODUCT_AREAS_BASE}`}
        description={`${entry.name} | ${area.name}`}
        language={entry.language}
      />
      <ProductHeader
        block={{
          headline: entry?.name,
        }}
        breadcrumbs={breadcrumbs}
      />

      <section className="py-block">
        <div className=" block-w-container">
          <div className="flex flex-row flex-wrap -mx-grid">
            <div className="w-full mb-8 md:w-1/3 lg:w-1/4 px-grid">
              <div className="py-4 border border-gray-100">
                {productGroups.map((group) => {
                  return (
                    <div className="block" key={group.node.fullSlug}>
                      <Link
                        className="inline-block py-2 text-sm px-grid hover:text-primary"
                        to={group.node.fullSlug}
                      >
                        {group.node.name}
                      </Link>
                    </div>
                  )
                })}
              </div>
            </div>
            <div className="w-full md:w-2/3 lg:w-3/4 px-grid">
              <div className="flex flex-row flex-wrap -mx-grid">
                {products.map((product) => (
                  <div
                    className="flex w-full mb-8 px-grid md:w-1/2 lg:w-1/3"
                    key={product.node.fullSlug}
                  >
                    <ProductCard
                      item={{
                        headline: switchedObjectNameOrLabel(product.node),
                        text: product.node.properties.groupName,
                        image: product.node.properties.mainImage,
                        variants: "8",
                        link: product.node.fullSlug,
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      {entry?.properties?.description?.length > 0 && (
        <section>
          <div className="pb-block block-w-container">
            <div className="flex flex-row flex-wrap -mx-grid">
              <div className="w-full lg:w-1/4 px-grid "></div>
              <div className="w-full lg:w-2/4 px-grid ">
                <Richtext text={entry?.properties?.description} />
              </div>
            </div>
          </div>
        </section>
      )}
    </Layout>
  )
}

export const query = graphql`
  query LineQuery(
    $id: String
    $lineId: String
    $areaId: String
    $locale: String
  ) {
    entry: contentServProductLine(ID: { eq: $id }, locale: { eq: $locale }) {
      name
      externalKey
      language
      ID
      fullSlug
      rootPage {
        name
        fullSlug
      }
      properties {
        description
        mainImage
      }
    }
    area: contentServProductArea(
      areaId: { eq: $areaId }
      locale: { eq: $locale }
    ) {
      name
      fullSlug
    }
    products: allContentServProduct(
      filter: { lineId: { eq: $lineId }, locale: { eq: $locale } }
      sort: { fields: treeSorting, order: ASC }
    ) {
      edges {
        node {
          name
          marketingLabel
          areaId
          groupId
          ID
          fullSlug
          parentId
          externalKey
          properties {
            mainImage
            groupName
          }
        }
      }
    }
    productGroups: allContentServProductGroup(
      filter: { lineId: { eq: $lineId }, locale: { eq: $locale } }
      sort: { fields: treeSorting, order: ASC }
    ) {
      edges {
        node {
          name
          lineId
          ID
          fullSlug
          externalKey
        }
      }
    }
  }
`

export default ProductLineTemplate
